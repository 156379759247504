import { Link } from 'react-router-dom'
import React from 'react'
import { useState } from 'react'
import Layout from '../Components/Layout'
import WhatsAppButton from '../Components/WhatsAppButton';
import Frequently from '../images/Frequently.png'


export default function Home() {
  const [faqItems] = useState([
    {
      id: 1,
      question: 'Do you offer managed IT services?',
      answer: ' Yes, we provide comprehensive managed IT services to proactively monitor and manage your IT infrastructure, ensuring optimal performance and minimizing downtime.'
    },
    {
      id: 2,
      question: 'How can your IT services benefit my business?',
      answer: 'Our IT services are designed to enhance the efficiency, security, and overall performance of your business operations. From improving workflow to safeguarding sensitive data, we tailor solutions to meet your specific needs.'
    },
    {
      id: 3,
      question: 'What is your approach to client communication?',
      answer: 'We prioritize transparent and regular communication with our clients. You will have access to a dedicated account manager and support team, ensuring that you are informed about the status of your IT services and any ongoing projects.'
    },
  ]);
  const [showAnswer, setShowAnswer] = useState(0); 

  const toggleAnswer = (id) => {
    setShowAnswer((prevId) => (prevId === id ? 0 : id));
  };

  return (
    <Layout>
      <WhatsAppButton/>
      {/* carousel */}
      <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval={3000}>
      <img src="../assets/images/slider1.jpg" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item" data-bs-interval={3000}>
      <img src="../assets/images/slider2.jpg" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item" data-bs-interval={3000}>
      <img src="../assets/images/slider3.jpg" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item" data-bs-interval={3000}>
      <img src="../assets/images/slider4.jpg" className="d-block w-100" alt="..." />
    </div>
  </div>

</div>
  {/* carousel end */}

{/* CARDS */}
  <div className="container ">
  <div className="row">
    <div className="col-md-4 mt-4">
    
        <img src="./assets/images/img1.png" className="card-img-top" alt="cards" />
       
     
    </div>
    <div className="col-md-4 mt-4">
  
        <img src="./assets/images/img2.png" className="card-img-top" alt="cards" />
  
    </div>
    <div className="col-md-4 mt-4">

        <img src="./assets/images/img3.png" className="card-img-top" alt="cards"  />



    </div>
  </div>
</div>
  
{/* CARDS END*/}

{/* SECTION FOR OVERLAY IMAGE */}
<div class="container" >


  <div class="row">
  <div className="col-md-6 text-light" style={{ marginTop: "80px" }}>
          <h2>We Bring Innovation to <span className='typewrite'>IT Services</span></h2>
          <p>
          Welcome to AmeerSoft, where technology meets innovation. As a leading provider of IT services, we pride ourselves on delivering cutting-edge solutions tailored to meet the unique needs of our clients.
          </p>

          <div className="row gx-2 gy-2 col-12">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-hover d-flex align-items-center custom-box">
                <i className="fas fa-globe"></i>
                <h5 className="mx-4 title-2 fw-bold">Worldwide Services</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-hover d-flex align-items-center custom-box">
                <i className="fas fa-headphones"></i>
                <h5 className="mx-4 title-2 fw-bold">24/7 Support</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-hover d-flex align-items-center custom-box">
                <i className="fas fa-gamepad"></i>
                <h5 className="mx-4 title-2 fw-bold">Easy Access</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-hover d-flex align-items-center custom-box">
                <i className="fas fa-star"></i>
                <h5 className="mx-4 title-2 fw-bold">Pioneering Solutions</h5>
              </div>
            </div>
          </div>
        </div>
 
    <div class="col-md-6 "   >
      <img src='./assets/images/abo.png' alt='About-img' className="img-fluid"/>
    </div>
 
    </div>
  </div>

{/* SECTION FOR END OVERLAY IMAGE */}
{/* SECTION OF PRICINGS */}
<div className="text-center text-light">
        <h1 className=" col-12 typewrite">Our Pricing</h1>
        <p className="col-lg-7 col-12 mx-auto">
        As a matter of fact, the unification of the coherent software provides strict control over The Accomplishment of Intended Estimation
        </p>
      </div>

      <div className="container">
      <div className="row gy-4 gx-4 justify-content-center">

        {/* Basic Plan*/}
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="pricing-card">
            <h1 className="my-2 title-2 text-light" >Basic</h1>
            <div className="price-circle">
              <h2 className="text-light">99$</h2>
              <h3 className="text-light">per project</h3>
              <div className='pricing-list my-4 text-light' >
            <p  className="my-1"><i className="fa-regular fa-circle-check"></i>single user</p>
            <p  className="my-1"><i className="fa-regular fa-circle-check"></i>no updates</p>
             <p  className="my-1"><i className="fa-regular fa-circle-check"></i>limited access library</p>
             <p  className="my-1"><i className="fa-regular fa-circle-check"></i>lorem ipsum</p>
             <p className="my-1"> <i className="fa-regular fa-circle-check"></i>dolor sit amet</p>
            </div>
              <Link to="/" className="btn btn-light my-4">Start Now</Link>
            </div>
          </div>
        </div>

        {/* Standard Plan */}
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="pricing-card">
            <h1 className="my-2 title-2 text-light">Standard</h1>
            <div className="price-circle">
              <h2 className="price text-light">99$</h2>
              <h3 className='text-light'>per project</h3>
              <div className='pricing-list my-4 text-light'>
            <p  className="my-1"><i className="fa-regular fa-circle-check"></i>single user</p>
            <p  className="my-1"><i className="fa-regular fa-circle-check"></i>no updates</p>
             <p  className="my-1"><i className="fa-regular fa-circle-check"></i>limited access library</p>
             <p  className="my-1"><i className="fa-regular fa-circle-check"></i>lorem ipsum</p>
             <p className="my-1"> <i className="fa-regular fa-circle-check"></i>dolor sit amet</p>
            </div>
              <Link to="/" className="btn btn-primary my-4">Start Now</Link>
            </div>
          </div>
        </div>

        {/* Premium Plan */}
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="pricing-card">
            <h1 className="my-2 title-2 text-light">Premium</h1>
            <h2 className="price text-white">99$</h2>
            <h3 className="text-light">per project</h3>
          <div className='pricing-list my-4 text-light'>
            <p  className="my-1"><i className="fa-regular fa-circle-check"></i>single user</p>
            <p  className="my-1"><i className="fa-regular fa-circle-check"></i>no updates</p>
             <p  className="my-1"><i className="fa-regular fa-circle-check"></i>limited access library</p>
             <p  className="my-1"><i className="fa-regular fa-circle-check"></i>lorem ipsum</p>
             <p className="my-1"> <i className="fa-regular fa-circle-check"></i>dolor sit amet</p>
            </div>
            <Link to="/" className="btn btn-light my-4">Start Now</Link>
          </div>
        </div>

      </div>
    </div>
   

{/* SECTION END OF PRICINGS */}
 {/* FREQUENTLY ASKED QUESTION */}
 <div className="text-center text-light mt-2">
 <h1 className="col-12 typewrite">Frequently Asked Questions</h1>
<p className="col-lg-7 col-12 mx-auto">
  At AmeerSoft, we understand that clear communication is essential. Our Frequently Asked Questions (FAQs) are designed to address common queries about our IT services. If you have specific questions that are not covered here, feel free to reach out to our dedicated support team for personalized assistance.
</p>
      </div>
 <div className="faq-container">
  <div className="row">
    <div className="col-md-6">
      <img src={Frequently} alt="frequently" className="img-fluid" />
    </div>
    
{/* "./assets/images/frequently.png" */}
  
    <div className="col-md-6 text-light" style={{marginTop:"110px"}}>
      {faqItems.map((item) => (
        <div
          key={item.id}
          className={`faq-content ${showAnswer === item.id ? 'active' : ''}`}
          onClick={() => toggleAnswer(item.id)}
        >
          <h3 className="faq-question">{item.question}</h3>
          {showAnswer === item.id && (
            <p className="faq-answer text-dark">{item.answer}</p>
          )}
        </div>
      ))}
    </div>
    </div>
    </div>
     
  
 {/* FREQUENTLY ASKED END QUESTION */}

    </Layout>
  )
}
