// import React from 'react';

// export default function VideoBackground() {
//   return (
//     <div>
//       {/* You can adjust the styles as needed */}
//       <img
//         className="video-background"
//         src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fgifer.com%2Fen%2Fgifs%2Ftechnology&psig=AOvVaw2ik3_Q74p76XQfhc3aWfSD&ust=1707992892089000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKj5lb_PqoQDFQAAAAAdAAAAABAE"
//         alt="Background GIF"
//       />
//     </div>
//   );
// }

import React from 'react';

export default function VideoBackground() {
  return (
    <div>
      <img
        className="video-background" src=".\assets\images\black.png" alt="Background GIF"/>
    </div>
  );
}
