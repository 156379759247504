import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <>
          <nav className="navbar top-fixed navbar-expand-lg navbar-light " style={{backgroundColor :"#000000"}}>
            <div className="container-fluid">
               
                  <Link className="navbar-brand" to="/">
                    <img src="./assets/images/logo.jpg" alt='' width={80}  />
                  </Link>
               
              <button className="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon "  />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item active">
                      <Link class="nav-link btn btn-outline-primary me-2 "  to="/">Home</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link btn btn-outline-primary me-2" to="/about">About</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link btn btn-outline-primary me-2" to="/service">Services</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link btn btn-outline-primary me-2" to="/contact">Contact</Link>
                    </li>
                   
                </ul>
              </div>
            </div>
          </nav>


    </>
  )
}
