import React from 'react';

const WhatsAppButton = () => {
  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: '1000',
  };

  const iconStyle = {
    fontSize: '24px',
    marginRight: '8px',
  };

  return (
    <div style={buttonStyle}>
      <a
        href="https://wa.me/923219470062" target="_blank" rel="noopener noreferrer"   className="btn btn-success"
      >
        <i className="fab fa-whatsapp" style={iconStyle}></i>
        Contact via WhatsApp
      </a>
    </div>
  );
};

export default WhatsAppButton;
