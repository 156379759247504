import React from 'react'
import Layout from '../Components/Layout'
import WhatsAppButton from '../Components/WhatsAppButton'


export default function Services() {
  return (
  <Layout>
       <>
    <WhatsAppButton/>
       <section className="service-section section-pt position-relative">

  <div className="container">
    <div className="row ">
    <div className="text-center text-light">
        <h1 className=" col-12 typewrite">Our Services</h1>
        <p className="col-lg-7 col-12 mx-auto">
        Explore a comprehensive suite of IT services designed to elevate your business. From robust cybersecurity measures to seamless cloud migrations, our offerings encompass network support, software development, IT consulting, and more. We are your trusted partner in navigating the complexities of the technological world.
        </p>
      </div>
    </div>
    {/* second row start */}
    <div className="row mb-n7 align-items-center">
      <div className="col-md-6 col-xl-4 mb-7">
        <div className="service-media-wrapper media-spacing-left">
          <div className="service-media">
            <img className="logo" src="assets/images/1.webp" alt=" service logo" />
            <div className="service-media-body">
              <h4 className="title text-light">
             Digital Solution
              </h4>
              <p className=" text-light">
                Pleasure rationally service
                are anyone who pursues
              </p>
            </div>
          </div>
          <div className="service-media">
            <img className="logo" src="assets/images/2.webp" alt=" service logo" />
            <div className="service-media-body">
              <h4 className="title text-light">
           Business Plan
              </h4>
              <p className=" text-light">
                Pleasure rationally extremy
                are anyone who loves
              </p>
            </div>
          </div>
          <div className="service-media">
            <img className="logo" src="assets/images/3.webp" alt=" service logo" />
            <div className="service-media-body">
              <h4 className="title text-light">
            Creative Strategy
              </h4>
              <p className=" text-light">
                Pleasure rationally service
                are anyone who loves
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
       
      </div>
      <div className="col-md-6 col-xl-4 mb-7">
        <div className="service-media-wrapper media-spacing-right">
          <div className="service-media">
            <img className="logo" src="assets/images/4.webp" alt=" service logo" />
            <div className="service-media-body">
              <h4 className="title text-light">Branding</h4>
              <p className=" text-light">
                Pleasure rationally service
                are anyone who loves
              </p>
            </div>
          </div>
          <div className="service-media">
            <img className="logo" src="assets/images/5.png" alt=" service logo" />
            <div className="service-media-body">
              <h4 className="title text-light">
         Marketing Policy
              </h4>
              <p className=" text-light">
                Pleasure rationally extremy
                are anyone who is pursues
              </p>
            </div>
          </div>
          <div className="service-media">
            <img className="logo" src="assets/images/6.webp" alt=" service logo" />
            <div className="service-media-body">
              <h4 className="title text-light">Campaign &amp; PR</h4>
              <p className=" text-light">
                Pleasure rationally service
                are anyone who pursues
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* second row end */}
  </div>
</section>


       
       
       </>

    </Layout>
  )
}
