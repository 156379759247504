import React from 'react'
import { Link } from 'react-router-dom'
function Footer() {
  return (
   <div>

  <footer className="text-center text-lg-start  text-muted" style={{backgroundColor
    :"#000000"}}>
    {/* Section: Social media */}
    <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom text-light">

    </section>
     {/* Section: Social media */}
    {/* Section: Links  */} 
    <section className>
      <div className="container text-center text-md-start mt-5 text-light">
         {/* Grid row */} 
        <div className="row mt-3">
           {/* Grid column */} 
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
             {/* Content */} 
            <h6 className="text-uppercase fw-bold mb-4">
              <i className=" me-3" />AmeerSoft
            </h6>
            <p>
            Discover a world of possibilities at our renowned training institute. From expert mentors to invaluable resources, everything you need to succeed awaits in our website footer.
            </p>
          </div>
           {/* Grid column */}
          {/* Grid column */} 
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 " >
             {/* Links */} 
            <h6 className="text-uppercase fw-bold mb-4">
             Courses
            </h6>
            <p>
              <Link  to ="/" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Home</Link>
            </p>
            <p>
              <Link to ="/about" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>About</Link>
            </p>
          
            <p>
              <Link to ="/contact" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Contact Us</Link>
            </p>
           
          </div>
           {/* Grid column */}
          {/* Grid column */} 
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
             {/* Links */} 
            <h6 className="text-uppercase fw-bold mb-4">
              Useful links
            </h6>
            <p>
              <Link to="/service" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Services</Link>
            </p>
            <p>
              <Link to="/contact" className="text-reset"  style={{ textDecoration: 'none',color:'#fff' }}>Contact Us</Link>
            </p>
           
          </div>
           {/* Grid column */} 
           {/* Grid column */} 
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        {/* Links */} 
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            <p><i className="fas fa-home me-3" /> Lahore, Pakistan</p>
           <p > <Link style={{ textDecoration: 'none', color:'#fff'}}>
              <i className="fas fa-envelope me-3 text-light" />
              info@AmeerSoft.com
            </Link></p>
            <Link style={{ textDecoration: 'none',color:'#fff' }}><i className="fas fa-phone me-3 text-light" /> +92 3219470062 </Link>
            
          </div>
           {/* Grid column */} 
        </div>
         {/* Grid row */} 
      </div>
    </section>
    <div className="text-center p-4 text-light" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
      © 2024 Copyright AmeerSoft
    </div>
  </footer>
</div> 
  )
}

export default Footer
