// Layout.js
import React from 'react';
import VideoBackground from './VideoBackground';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div>
     <Navbar/>
      <main>
        <VideoBackground />
        {children}
      </main>
      <Footer/>
    </div>
  );
};

export default Layout;
