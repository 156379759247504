import React from 'react'
import { useState } from 'react';
import Layout from '../Components/Layout'
import WhatsAppButton from '../Components/WhatsAppButton'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

export default function About() {
  const[counterOn,setCounteron]=useState(false);
  return (
    <Layout>
      <WhatsAppButton/>
      {/* THREE DIVS SECTION */}
      <div className="container ">
  <div className="row">
    <div className="col-md-12 text-light">
      <h1 className="text-center ">We are <span className='typewrite'>Different</span> because</h1>
      <p className="text-center">Although, Final Stages Of The Internal Network Gives A Complete Experience Of The Parameter Of Speculative Environment</p>
    </div>
  </div>
</div>
<div className="container">
  <div className="row">
    <div className="col-md-4 mt-4">
      <div className="card about-div">
        <i className="fas fa-shield-alt"></i>
        <h4>Strong Security</h4>
        <p>Ensuring the highest level of security for your data.</p>
      </div>
    </div>
    <div className="col-md-4 mt-4">
      <div className="card about-div" style={{ backgroundColor:"#097577"}}>
        <i className="fab fa-react text-light"></i>
        <h4 className='text-light'>React Services</h4>
        <p className='text-light'>Using React to build responsive and dynamic web applications.</p>
      </div>
    </div>
    <div className="col-md-4 mt-4 ">
      <div className="card about-div">
        <i className="fas fa-lightbulb"></i>
        <h4 >Creative Ideas</h4>
        <p>Bringing innovative and creative solutions to the table.</p>
      </div>
    </div>
  </div>
</div>
      {/* THREE DIVS END SECTION */}
      {/* SECTION FOR REVIEWS  */}
      <div className="container mt-5">
      <div className="row">
        <div className="col-lg-6">
          <div className='text-center text-light'>
            <h2>Testimonials</h2>
            <p>Here are some feedback from our clients</p>
            {/* <!-- Carousel wrapper --> */}
  <div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-inner">
   
    <div className="carousel-item  active">
          <img className="rounded-circle shadow-1-strong mb-4" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp" alt="avatar" style={{width: 150}} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h5 className="mb-3">John Doe</h5>
              <p>Web Developer</p>
              <p className="text-light">
                <i className="fas fa-quote-left pe-2" />
                Exceptional service and expertise! AmeerSoft has been instrumental in transforming our IT infrastructure. Their team is responsive, knowledgeable, and always goes the extra mile to ensure our systems run seamlessly. Highly recommend!
              </p>
            </div>
          </div>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="far fa-star fa-sm" /></li>
          </ul>
        </div> 
    
    <div className="carousel-item">
    <img className="rounded-circle shadow-1-strong mb-4" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" alt="avatar" style={{width: 150}} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h5 className="mb-3">Anna Deynah</h5>
              <p>UX Designer</p>
              <p className="text-light">
                <i className="fas fa-quote-left pe-2" />
                We partnered with AmeerSoft for a complex cloud migration project, and the results were outstanding. Their attention to detail and proactive communication made the process smooth. Looking forward to future collaborations!
              </p>
            </div>
          </div>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="far fa-star fa-sm" /></li>
          </ul>
        </div>
    <div className="carousel-item">
    <img className="rounded-circle shadow-1-strong mb-4" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp" alt="avatar" style={{width: 150}} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h5 className="mb-3">John Doe</h5>
              <p>Web Developer</p>
              <p className="text-light">
                <i className="fas fa-quote-left pe-2" />
                Impressed by the professionalism and efficiency of Ameersoft.They provided us with top-notch cybersecurity solutions that have significantly enhanced our data protection. Grateful for their dedication to security excellence!
              </p>
            </div>
          </div>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="far fa-star fa-sm" /></li>
          </ul>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

</div>

          <div>
</div>
        </div>
        <div className="col-lg-6">
          <img
            src="./assets/images/Testimonial.jpg" alt="Testimonial" className="img-fluid"
          />
        </div>
      </div>
      </div>
        {/* SECTION FOR REVIEWS ENDS HERE */}

{/* COUNTER ANIMATION START HERE */}
<div className="container">
<div className="row mt-5">
  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #09a2a5' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#fff' }}>Projects Done</p>
      </div>
    </ScrollTrigger>
  </div>

  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #09a2a5' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#fff' }}>Clients Happy</p>
      </div>
    </ScrollTrigger>
  </div>

  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #09a2a5' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#fff' }}>Awards</p>
      </div>
    </ScrollTrigger>
  </div>

  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #09a2a5' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#fff' }}>Employees</p>
      </div>
    </ScrollTrigger>
  </div>
</div>
</div>
{/* COUNTER ANIMATION END HERE */}

    </Layout>
  )
}
