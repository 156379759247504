import React from 'react'
import Layout from '../Components/Layout'
import WhatsAppButton from '../Components/WhatsAppButton'

export default function Contact() {
  return (
    <Layout>
      <WhatsAppButton/>
      <div className="container">
      <div className="row">
        <div className="col-lg-6" style={{ marginTop: "80px" }}>
          <div className='text-center text-light'>
            <h2>Contact Us</h2>
            <p>What sets us apart is our unwavering commitment to quality, security, and client success. We leverage our expertise to address the dynamic needs of businesses, ensuring they stay ahead in an ever-evolving technological landscape. Choose [Your Company Name] for unparalleled IT solutions that make a difference.</p>
          

</div>

          <div>
</div>
        </div>
        <div className="col-lg-6">
          <img
            src="./assets/images/contactus.jpg" alt="Testimonial" className="img-fluid" style={{borderRadius:"15px"}}
          />
        </div>
      </div>
      </div>

      <div className="container">
  <div className="row text-light">
    <div className="col-md-4 mt-4">
      <div className="contact-info">
      <h5><i class="fa-solid fa-envelope"></i> example@example.com</h5>
      <h5><i class="fa-solid fa-phone"></i> +123 456 6789</h5>
      <h5><i class="fa-solid fa-address-book"></i> Lahore,Pakistan</h5>
      </div>
      <div className="vertical-line">

      </div>
    </div>
    <div className="col-md-4 mt-4">
      <div className="contact-info">
      <h5><i class="fa-solid fa-envelope"></i> example@example.com</h5>
      <h5><i class="fa-solid fa-phone"></i> +123 456 6789</h5>
      <h5><i class="fa-solid fa-address-book"></i> Lahore,Pakistan</h5>
      </div>
   
    </div>
    <div className="col-md-4 mt-4">
      <div className="contact-info">
      <h5><i class="fa-solid fa-envelope"></i> example@example.com</h5>
      <h5><i class="fa-solid fa-phone"></i> +123 456 6789</h5>
      <h5><i class="fa-solid fa-address-book"></i> Lahore,Pakistan</h5>
      </div>
    </div>
  </div>
</div>
<iframe class="contact-map"  title="Your Unique and Descriptive Title" 
 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9050207912896!2d-0.14675028449633118!3d51.514958479636384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ad554c335c1%3A0xda2164b934c67c1a!2sOxford+St%2C+London%2C+UK!5e0!3m2!1sen!2sro!4v1485889312335" allowfullscreen></iframe>


      
    </Layout>
  )
}
